import React, { useState, useEffect } from 'react';
import axios from 'axios';

function Weather() {
  const [city, setCity] = useState('');
  const [weather, setWeather] = useState(null);
  const [forecast, setForecast] = useState(null);
  const apiKey = '3a6848bbdc6015f04177a3a61c63ff13';

  const fetchWeather = async (cityName) => {
    try {
      const weatherResponse = await axios.get(
        `https://api.openweathermap.org/data/2.5/weather?q=${cityName}&appid=${apiKey}&units=metric`
      );
      setWeather(weatherResponse.data);

      const forecastResponse = await axios.get(
        `https://api.openweathermap.org/data/2.5/forecast?q=${cityName}&appid=${apiKey}&units=metric`
      );
      setForecast(forecastResponse.data.list);
    } catch (error) {
      console.error('Error fetching weather data:', error);
      alert('Could not fetch weather data. Please check the city name or try again later.');
      setWeather(null);
      setForecast(null);
    }
  };

  const fetchWeatherByLocation = async (latitude, longitude) => {
    try {
      const weatherResponse = await axios.get(
        `https://api.openweathermap.org/data/2.5/weather?lat=${latitude}&lon=${longitude}&appid=${apiKey}&units=metric`
      );
      setWeather(weatherResponse.data);

      const forecastResponse = await axios.get(
        `https://api.openweathermap.org/data/2.5/forecast?lat=${latitude}&lon=${longitude}&appid=${apiKey}&units=metric`
      );
      setForecast(forecastResponse.data.list);
    } catch (error) {
      console.error('Error fetching weather data:', error);
    }
  };

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        fetchWeatherByLocation(latitude, longitude);
      },
      (error) => console.error('Error accessing location:', error)
    );
  }, []);

  // Slideshow functionality
  const images = [
    'https://www.our-competencies.com/slide/p1.jpg',
    'https://www.our-competencies.com/slide/p2.jpg',
    'https://www.our-competencies.com/slide/p3.jpg',
    'https://www.our-competencies.com/slide/p4.jpg',
    'https://www.our-competencies.com/slide/p5.jpg',
    'https://www.our-competencies.com/slide/p6.jpg',
    'https://www.our-competencies.com/slide/p7.jpg',
    'https://www.our-competencies.com/slide/p8.jpg',
    'https://www.our-competencies.com/slide/p9.jpg',
    'https://www.our-competencies.com/slide/p10.jpg',
    'https://www.our-competencies.com/slide/p11.jpg',
    'https://www.our-competencies.com/slide/p12.jpg',
    'https://www.our-competencies.com/slide/p13.jpg',
    'https://www.our-competencies.com/slide/p14.jpg',
    'https://www.our-competencies.com/slide/p15.jpg',
  ];
  const [currentImage, setCurrentImage] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % images.length);
    }, 5000); // Change image every 5 seconds
    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <div style={{ position: 'relative', height: '100vh', color: '#fff', fontFamily: 'Arial, sans-serif' }}>
      <div
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          backgroundImage: `url(${images[currentImage]})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          opacity: '0.5', // 50% opacity
          zIndex: '-1',
          transition: 'background-image 1s ease-in-out', // Smooth transition between images
        }}
      ></div>

      <div style={{ textAlign: 'center', paddingTop: '20px', backgroundColor: 'rgba(0, 0, 0, 0.5)', height: '100%' }}>
        <h2 style={{ fontSize: '2.5rem', fontWeight: 'bold', marginBottom: '20px' }}>Weather Forecast</h2>
        <input
          type="text"
          placeholder="Enter city name"
          value={city}
          onChange={(e) => setCity(e.target.value)}
          style={{ padding: '10px', fontSize: '18px', borderRadius: '5px', border: 'none' }}
        />
        <button onClick={() => fetchWeather(city)} style={{ padding: '10px 20px', fontSize: '18px', marginLeft: '10px', borderRadius: '5px', backgroundColor: '#2196F3', color: '#fff', cursor: 'pointer' }}>
          Get Weather
        </button>

        {weather && (
          <div style={{ marginTop: '30px', animation: 'fadeIn 1s' }}>
            <h3>Current Weather in {weather.name}</h3>
            <p>Temperature: {weather.main.temp}°C</p>
            <p>Feels Like: {weather.main.feels_like}°C</p>
            <p>Weather: {weather.weather[0].main} - {weather.weather[0].description}</p>
            <p>Humidity: {weather.main.humidity}%</p>
            <p>Wind Speed: {weather.wind.speed} m/s</p>
          </div>
        )}

        {forecast && (
          <div style={{ marginTop: '30px', animation: 'fadeIn 1s' }}>
            <h3>5-Day Forecast</h3>
            <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
              {forecast.slice(0, 5).map((item, index) => (
                <div key={index} style={{ margin: '10px', padding: '15px', border: '1px solid #ccc', borderRadius: '8px', backgroundColor: 'rgba(255, 255, 255, 0.1)', width: '150px' }}>
                  <p><strong>{new Date(item.dt_txt).toLocaleDateString()}</strong></p>
                  <p>Temp: {item.main.temp}°C</p>
                  <p>{item.weather[0].description}</p>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Weather;
